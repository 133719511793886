<template>
  <div class="user-account">
    <v-row class="w-100 ma-auto">
      <v-col cols="12" md="5" class="user-search ">
        <div class="search-input white">
          <v-text-field
            class="px-8"
            color="primary"
            placeholder="Search Users"
            append-icon="mdi-magnify"
            hide-details="true"
          ></v-text-field>
        </div>
        <div class="users px-8 py-3">
          <!-- <span class="d-block font-14 white-grey--text mb-3">A</span> -->
          <div v-for="(host, i) in hosts" :key="i">
            <router-link :to="`/hosts/account/${host._id}`" tag="div">
              <div class="d-flex align-center mt-5 cursor-pointer">
                <div class="avatar mr-3">
                  <img class="object-cover" v-if="host.picture" :src="host.picture.url" alt="" />
                  <img v-else :src="avatar" alt="" />
                </div>
                <div>
                  <span class="black--text font-13 font-500 d-block">{{
                    host.firstName + " " + host.lastName
                  }}</span>
                  <span class="black-grey--text font-12 d-block">{{
                    host.email
                  }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="7" class="px-2">
        <div class="b-left pt-4 px-5">
          <!-- user avatar -->
          <v-row>
            <v-col cols="12" md="3">
              <div class="account-avatar text-center mx-auto">
                <img v-if="item.picture" :src="item.picture.url" alt="" />
                <img v-else :src="avatar" alt="" />
              </div>
            </v-col>
            <v-col cols="12" md="5">
              <div class="pt-2">
                <span class="d-block font-20 font-500 black--text">{{
                  item.firstName + " " + item.lastName
                }}</span>
                <span class="d-block font-14 black-grey--text" v-if="item.promo">
                  <a :href="item.promo" target="_blank">Promo</a>
                </span>
                <div class="mt-3 d-flex align-center">
                  <div class="yellow py-2 px-4 d-inline-block radius-5 mr-3">
                    <span class="white--text font-500 font-16">{{
                      activities.length
                    }}</span>
                  </div>
                  <span class="font-14 black-grey--text">Activities</span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <div class="pt-2">
                <v-btn
                  color="secondary"
                  elevation="0"
                  block
                  @click="messageDialog = true"
                >
                  <span class="white--text font-14">Message</span>
                </v-btn>
                <v-btn class="base-btn mt-2 white radius-5" elevation="0" block>
                  <span class="black--text font-14">Notification</span>
                </v-btn>
                <v-btn
                  class="mt-2 white radius-5 w-100"
                  elevation="0"
                  block
                  color="white"
                >
                  <span class="black--text font-14">Block Account</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <!-- user details -->
          <div class="px-4 mt-5">
            <span class="d-block font-14 white-grey--text font-500 mb-3"
              >Basic Details</span
            >
            <v-row>
              <v-col cols="12" md="4">
                <div class="d-flex align-center">
                  <img
                    class="mr-3"
                    :src="require('@/assets/images/icons/message.png')"
                    alt=""
                  />
                  <span class="font-14 black--text">{{ item.email }}</span>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-flex align-center">
                  <img
                    class="mr-3"
                    :src="require('@/assets/images/icons/phone.png')"
                    alt=""
                  />
                  <span class="font-14 black--text">{{ item.phone }}</span>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-flex align-center">
                  <img
                    class="mr-3"
                    :src="require('@/assets/images/icons/location.png')"
                    alt=""
                  />
                  <span class="font-14 black--text">{{ item.country }}</span>
                </div>
              </v-col>
            </v-row>

            <span class="d-block font-14 white-grey--text font-500 mt-4"
              >BIO</span
            >
            <p class="font-14 black-grey--text mt-3">
              {{ item.bio[$root.language] }}
            </p>
          </div>

          <div class="divider-dark mt-10 mb-6"></div>

          <!-- events -->
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mb-3"
              v-for="(activity, i) in activities"
              :key="i"
            >
              <router-link :to="`/experiences/${activity._id}`" tag="div">
                <div class="photo-details pa-2">
                  <div class="img-cover cursor-pointer">
                    <img
                      v-if="activity.coverImage"
                      height="180"
                      class="object-cover"
                      width="100%"
                      :src="activity.coverImage.url"
                      alt=""
                    />
                  </div>

                  <span
                    class="d-block font-15 font-500 mt-2 mb-1 black--text"
                    >{{ activity.title[$root.language] }}</span
                  >
                  <span class="d-block font-13 mt-1 mb-1 black-grey--text">{{
                    activity.mainLocation
                  }}</span>
                  <div
                    class="d-flex align-center justify-space-between pb-2 mt-1 b-bottom"
                  >
                    <div class="d-flex align-center">
                      <v-icon class="mr-2" color="black-grey" size="20"
                        >mdi-calendar-blank-outline</v-icon
                      >
                      <span
                        class="d-block font-13 mt-1 mb-1 black-grey--text"
                        v-if="activity.date[0]"
                        >{{ $moment(activity.date[0].start).format("l") }}</span
                      >
                    </div>
                    <div class="d-flex align-center">
                      <v-icon class="mr-2" color="black-grey" size="20"
                        >mdi-gesture-tap</v-icon
                      >
                      <span class="d-block font-14 mt-1 mb-1 black-grey--text"
                        >{{ activity.activityClicks }} click</span
                      >
                    </div>
                  </div>

                  <!-- <div class="py-3 d-flex align-center justify-space-between">
                  <span class="font-14 blue--text">Published</span>
                  <span class="black--text font-18 font-500">LEB10,000/p</span>
                </div> -->
                </div>
              </router-link>
            </v-col>
          </v-row>

          <!-- <div class="d-flex align justify-space-between">
            <span class="font-12 black-grey--text">Reviews (9)</span>
            <span class="font-12 black-grey--text">See All</span>
          </div>
          <div class="mb-12">
            <div class="mt-3">
              <span class="black--text font-16"
                >Activity Name Attended on: 9 Nov 2020</span
              >
              <p class="font-14 black-grey--text">
                I've had the pleasure to work with Angelina on a number of
                projects requiring quality check. It's not easy communicating
                project requirements, especially aesthetics, when your design
                work is being done remotely.
              </p>
            </div>
          </div> -->
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="messageDialog" max-width="400" content-class="white">
      <div class="pa-3">
        <div class="d-flex align-center justify-space-between b-bottom mb-2">
          <span class="d-block font-16 font-500">Message</span>
          <v-icon @click="messageDialog = false" size="20" color="primary"
            >mdi-close</v-icon
          >
        </div>
        <p>{{ item.message }}</p>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    paramID: "",
    paramItem: {},
    hosts: [],
    item: {},
    activities: [],
    messageDialog: false,
  }),
  watch: {
    "$route.params": {
      async handler() {
        // fetch item by id
        await this.fetchHosts();
        this.paramID = this.$route.params.id;
        if (!this.paramID) {
          this.paramItem = this.hosts[0];
        } else {
          this.paramID = this.$route.params.id;
          this.paramItem = this.hosts.filter(
            (item) => item._id == this.paramID
          )[0];
        }
        await this.fetchHostByID();
      },
    },
  },
  methods: {
    async fetchHostByID() {
      let { data } = await this.$axios.get(`/host/${this.paramID}`);

      if (data) {
        this.item = data.data.hostData;
        this.activities = data.data.activityData;
      }
    },
    async fetchHosts() {
      let { data } = await this.$axios.get("host/all/new?pageSize=1000");
      if (data) {
        this.hosts = data.data;
      }
    },
  },
  async created() {
    await this.fetchHosts();
    // fetch item by id
    this.paramID = this.$route.params.id;
    if (!this.paramID) {
      this.paramItem = this.hosts[0];
    } else {
      this.paramID = this.$route.params.id;
      this.paramItem = this.hosts.filter((item) => item._id == this.paramID)[0];
    }
    await this.fetchHostByID();
  },
};
</script>

<style lang="scss">
.user-account {
  height: calc(100vh - 70px);
  overflow: hidden;
  .user-search {
    .users {
      height: 100vh;
      overflow-y: scroll;
      background: #fbfbfd;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      border: none !important;
    }
  }

  .b-left {
    border-left: 2px solid $grayicon;
    position: relative;
    left: -2px;
    height: calc(100vh - 70px);
    overflow-y: scroll;

    .account-avatar {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
